import ProfileImage from "./../../../../assets/images/users/user2.jpg";
import { useDispatch, useSelector } from "react-redux"; 
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell,faCheckCircle, faEye } from "@fortawesome/free-solid-svg-icons"; 
import { getAllUnreadNotification,getAllUnreadNotificationData,markAsReadNotification } from "../../../../services/notificationServices"; 
import config from "../../../../config"; 

const RightNavBar = () => {
    const userAccount = useSelector((state)=>state.account);
    const [isOpenProfileBox,setProfileBox] = useState(false);
    const [isNotificationOpen,setNotificationOpen] = useState(false);
    const [getAllUnReadNotification,setAllUnReadNotification] = useState([]); 
    const [getAllReadNotification,setAllReadNotification] = useState([]); 
    const msg = new SpeechSynthesisUtterance();
    const dispatcher = useDispatch();
    useEffect(()=>{
        let allowToSpeak = true;
        fetchNotificationData(allowToSpeak);  
    },[]);

    const notificationRData = useSelector((state) => state.notificationData);

    const fetchNotificationData = (allowToSpeak) => { 
        const appHeader = {
            Authorization: userAccount?.token,
            }; 
            getAllUnreadNotificationData(dispatcher,appHeader);
            // .then((data) => { 
            //     setAllUnReadNotification(data?.data?.unSeenNotification);
            //     setAllReadNotification(data?.data?.seenNotification);
            //     if(data?.data?.unSeenNotification && data?.data?.unSeenNotification.length >0){
                    
            //         if(allowToSpeak)
            //         {
            //             let name ="";
            //             if(userAccount?.user?.firstName && userAccount?.user?.lastName){
            //                 name = "Hi "+userAccount?.user?.firstName+" "+userAccount?.user?.lastName+" , ";
            //             }
            //             msg.text = name+"You got the "+data?.data?.unSeenNotification.length+" New Notification on rsquare Portal";
            //             window.speechSynthesis.speak(msg); 
            //         }
                   
            //     }
            // })
            // .catch((error) => {
            //     setAllUnReadNotification([]);
            // });
    };

    useEffect(()=>{
        let dataValues = notificationRData.notificationCountDataValues; 

        if(dataValues?.unSeenNotification && dataValues?.unSeenNotification.length>-1){
            setAllUnReadNotification(dataValues?.unSeenNotification);
            setAllReadNotification(dataValues?.seenNotification);
            if(dataValues?.unSeenNotification && dataValues?.unSeenNotification.length >0){ 
                 
                    // let name ="";
                    // if(userAccount?.user?.firstName && userAccount?.user?.lastName){
                    //     name = "Hi "+userAccount?.user?.firstName+" "+userAccount?.user?.lastName+" , ";
                    // }
                    // msg.text = name+"You got the "+dataValues?.unSeenNotification.length+" New Notification on rsquare Portal";
                    // window.speechSynthesis.speak(msg); 
                   
                
            }
        }

    },[notificationRData.notificationCountDataValues]);

    const markAsRead = (notificationId) => {
        let data = {
            notificationid:notificationId
        };
        const appHeader = {
            Authorization: userAccount?.token,
            }; 

        markAsReadNotification(data,appHeader).then((data) => {
            fetchNotificationData(false);
         }).catch((error) => {
            fetchNotificationData(false);
         });
    }

    return (
        <>
                <div className="app-navbar flex-shrink-0">
                    <div className="app-navbar-item ms-1 ms-lg-3">
                    <div className="btn btn-icon active btn-custom btn-icon-muted btn-active-light w-35px h-35px w-md-40px h-md-40px position-relative" onClick={()=>{
                        setNotificationOpen(true);
                    }}>
                        <span className="svg-icon svg-icon-1">
                        <FontAwesomeIcon 
                           icon={faBell}  
                           size="2xs"
                           style={{ 
                             cursor: "pointer",
                             color:"#ccc",
                             marginTop:"10px"
                           }}
                        /> 
                        </span> 
                        <span className={(getAllUnReadNotification.length >0)?"position-absolute translate-middle top-0 start-50 animation-blink":"position-absolute translate-middle top-0 start-50"} style={{backgroundColor:"#BADA55",color:"white",fontSize:"12px",borderRadius:"10px / 30px",width:"25px",height:"18px"}}>{getAllUnReadNotification.length}</span>
                    </div>
                    </div>
                    <div className="app-navbar-item ms-1 ms-lg-3"> 
                        <div className="cursor-pointer symbol symbol-35px symbol-md-40px">
                            <img src={ProfileImage} alt="user" 
                                        onClick={()=>{
                                            setProfileBox(!isOpenProfileBox);
                                        }} />
                        </div>  
                        {
                            (isOpenProfileBox)?<><div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px show" data-kt-menu="true"
                        style={{zIndex:"105",position:"fixed",inset:"0px 0px auto auto",margin:"0px",transform:"translate(-30px, 70px)"}}
                        >
                            
                            <div className="menu-item px-3">
                                <div className="menu-content d-flex align-items-center px-3"> 
                                    <div className="symbol symbol-50px me-5">
                                        <img alt="Logo" src={ProfileImage} />
                                    </div> 
                                    <div className="d-flex flex-column">
                                        <div className="fw-bold d-flex align-items-center fs-5">
                                            {userAccount.user?.firstName} {userAccount.user?.lastName}
                                        </div>
                                        <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">{userAccount.user?.email}</a>
                                    </div> 
                                </div>
                            </div> 
                            <div className="separator my-2"></div> 
                            <div className="menu-item px-5">
                                <a className="menu-link px-5">My Profile</a>
                            </div> 
                            <div className="menu-item px-5">
                                <Link to="/my-notifications" className="menu-link px-5">My Notifications ({getAllUnReadNotification.length})</Link>
                            </div> 
                            <div className="separator my-2"></div>   
                            <div className="menu-item px-5">
                                <Link  className="menu-link px-5" to="/logout"> Sign Out </Link> 
                            </div> 
                        </div>
                        <div className="drawer-overlay" style={{zIndex:10}} onClick={()=>{
                            setProfileBox(!isOpenProfileBox);
                        }}></div>
                        </>:null
                        }
                    </div>
                </div>
                {
                (isNotificationOpen)?<>
                <div className="bg-white drawer drawer-end drawer-on" style={{width:"500px"}}>
                    <div className="card w-100 rounded-0">
                        <div className="card-header pe-5">
                            <div className="card-title">
                                <div className="d-flex justify-content-center flex-column me-3">Unread Notification ({getAllUnReadNotification.length})</div>
                            </div>
                            <div className="card-toolbar">
                                <div className="btn btn-sm btn-icon btn-active-light-primary" onClick={()=>{
                                    setNotificationOpen(false);
                                }}><span className="svg-icon svg-icon-1">X</span></div>
                            </div>
                        </div>
                        {
                            (getAllUnReadNotification.length > 0)?<>
                        <div className="card-body hover-scroll-overlay-y">
                            {
                                getAllUnReadNotification.slice(0, 5).map((notificationData,i)=>{
                                    return(
                                        <div className="timeline">
                                        <div className="timeline-item">
                                            <div className="timeline-line w-40px"></div>
                                            <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                                                <div className="symbol-label bg-light">
                                                <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor"></path>
                                                        <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor"></path>
                                                    </svg>
                                                </span>
                                                </div>
                                            </div>
                                            <div className="timeline-content mb-10 mt-n1">
                                                <div className="pe-3 mb-5">
                                                    <div className="fs-5 fw-semibold mb-2">
                                                        {
                                                        (notificationData.notification_page == "booking")?"Booking Id : #"+notificationData.notification_pageid:null
                                                        }
                                                        <br/>
                                                         {notificationData.message}
                                                         </div>
                                                    <div className="d-flex align-items-center mt-1 fs-6">
                                                        <div className="text-muted me-2 fs-7">Added at {notificationData.notification_date} by {notificationData.first_name} {notificationData.last_name}</div>
                                                    </div>
                                                    {
                                                        (notificationData.notification_page == "booking")? <Link
                                                        onClick={()=>{
                                                            setNotificationOpen(false);
                                                        }}
                                                        to={"/booking/" + notificationData.notification_pageid} 
                                                        className="text-center"
                                                      ><button className="btn btn-primary mt-3" style={{height:"35px",padding:"8px"}}>
                                                        <FontAwesomeIcon 
                                                            icon={faEye}   
                                                            style={{ 
                                                                cursor: "pointer",
                                                                color:"white", 
                                                                marginRight:"5px"
                                                            }}
                                                            /> 
                                                        View Booking</button></Link>:null
                                                    }
                                                    <button className="btn btn-secondary mt-3"
                                                    onClick={()=>{
                                                        markAsRead(notificationData.id);
                                                    }}
                                                    style={{height:"35px",padding:"8px",marginLeft:"10px"}}>
                                                    <FontAwesomeIcon 
                                                            icon={faCheckCircle}   
                                                            style={{ 
                                                                cursor: "pointer",
                                                                color:"#ccc", 
                                                                marginRight:"5px"
                                                            }}
                                                            /> 
                                                        Mark As Read</button>
                                                </div>
                                            </div>
                                        </div> 
                                    </div> 
                                    );
                                })
                            }
                           
                        </div>
                        <div className="card-footer">
                            <div className="py-3 text-center">
                                <div className="btn btn-color-gray-600 btn-active-color-primary"> 
                                <Link to="/my-notifications" onClick={()=>{
                                    setNotificationOpen(false);
                                }} className="menu-link px-5"> View All Notification </Link>
                                <span className="svg-icon svg-icon-5">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                                </svg>    
                                </span></div>
                            </div>
                        </div>
                        </>:<>
                        <div className="card-body hover-scroll-overlay-y">
                        <   div className="py-3 text-center text-gray-900 text-hover-primary fs-2 fw-bold me-1"> No Notification Found </div>
                        </div>
                        <div className="card-footer">
                            <div className="py-3 text-center">
                                <div className="btn btn-color-gray-600 btn-active-color-primary"> 
                                <Link to="/my-notifications" onClick={()=>{
                                    setNotificationOpen(false);
                                }} className="menu-link px-5"> View All Notification </Link>
                                <span className="svg-icon svg-icon-5">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                                </svg>    
                                </span></div>
                            </div>
                        </div>
                        </>
                    }
                    </div>
                </div> 
                <div className="drawer-overlay" style={{zIndex:"109"}} onClick={()=>{
                     setNotificationOpen(false);
                }}></div>
                </>:null
                }
                </>

    )
}

export default RightNavBar;