import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// project imports
import MainLayout from "./../layout/MainLayout";
import Loadable from "../components/Loadable";
import AuthGuard from "./../utils/route-guard/AuthGuard";
import AuthVerify from "../utils/route-guard/AuthVerify";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("../views/pages/dashboard"))
);
const LogoutScreen = Loadable(
  lazy(() => import("../views/pages/authentication/Logout"))
);

const Createbooking = Loadable(
  lazy(() => import("../views/pages/bookings/Createbooking"))
);
const Viewbooking = Loadable(
  lazy(() => import("../views/pages/bookings/Viewbooking"))
);
const TableViewBooking = Loadable(
  lazy(() => import("../views/pages/bookings/TableViewBooking"))
);

const SingleBooking = Loadable(
  lazy(() => import("../views/pages/bookings/SingleBooking"))
);

const AddCustomer = Loadable(
  lazy(() => import("../views/pages/customers/AddCustomer"))
);
const ViewCustomer = Loadable(
  lazy(() => import("../views/pages/customers/AllCustomer"))
  //lazy(() => import("../views/pages/customers/ViewCustomer")) 
);

const ViewDetailCustomer = Loadable(
  lazy(() => import("../views/pages/customers/ViewCustomer")) 
);

const AddShippingLine = Loadable(
  lazy(() => import("../views/pages/shippingline/AddShippingLine"))
);
const ViewShippingLine = Loadable(
  lazy(() => import("../views/pages/shippingline/ViewShippingLine"))
);

const ViewUsers = Loadable(lazy(() => import("../views/pages/users/ViewUser")));
const AddUsers = Loadable(lazy(() => import("../views/pages/users/AddUser")));
const ViewUserDetails = Loadable(
  lazy(() => import("../views/pages/users/ViewUserDetails"))
);

const ViewInvoices = Loadable(
  lazy(() => import("../views/pages/invoices/ViewInvoices"))
);

const DueInvoices = Loadable(
  lazy(() => import("../views/pages/invoices/DueInvoices"))
);

const SubmitMrn = Loadable(
  lazy(() => import("../views/pages/mrn/SubmitMrn"))
);

const CompletedMrn =  Loadable(
  lazy(() => import("../views/pages/mrn/CompletedMrn"))
);


const UsersBookings = Loadable(
  lazy(() => import("../views/pages/mybookings/UsersBookings"))
);

const MyNotifications = Loadable(
  lazy(() => import("../views/pages/notification/AllNotifications"))
);

const InProgressBookings = Loadable(
  lazy(()=> import("../views/pages/bookings/InProgressBookings"))
);



const AddCustomAgent = Loadable(
  lazy(() => import("../views/pages/customagents/AddCustomAgent"))
); 
const ViewCustomAgents = Loadable(
  lazy(() => import("../views/pages/customagents/ViewCustomAgents")) 
);

const AddTransporterAgent = Loadable(
  lazy(() => import("../views/pages/transportationagents/AddTransportationagents"))
); 
const ViewTransporterAgents = Loadable(
  lazy(() => import("../views/pages/transportationagents/ViewTransportationagents")) 
);


const EmailMarketingCompanies = Loadable(
  lazy(() => import("../views/pages/emailmarketing/allcompanies")) 
);

const EmailMarketingSendQuotes = Loadable(
  lazy(() => import("../views/pages/emailmarketing/sendEmailQuotes")) 
);

const ViewEmailMarketingCompany = Loadable(
  lazy(() => import("../views/pages/emailmarketing/viewCompany")) 
);


const DeadlineTracker = Loadable(
  lazy(() => import("../views/pages/deadlines/DeadlineTracker")) 
);


const ViewLoadingSupplier = Loadable(
  lazy(() => import("../views/pages/loadingsuppliers/ViewLoadingSupplier")) 
);

const AddLoadingSupplier = Loadable(
  lazy(() => import("../views/pages/loadingsuppliers/AddLoadingSupplier")) 
);



//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        "/dashboard",
        "/create-booking",
        "/booking-deadline-tracker",
        "/view-booking",
        "/add-customer",
        "/view-customer",
        "/add-shippingline",
        "/view-shippingline",
        "/add-user",
        "/view-user",
        "/booking/:id",
        "/user-detail/:id",
        "/customer-detail/:id",
        "/invoices",
        "/due-invoices",
        "/my-bookings",
        "/my-notifications",
        "/view-inprogress-booking",
        "/submit-mrn",
        "/completed-mrn",
        "/add-loadingsupplier",
        "/view-loadingsupplier",
        "/add-customeagent",
        "/view-customeagent",
        "/add-transportationagent",
        "/view-transportationagent",
        "/email-marketing-companies",
        "/send-email-quotes",
        "/view-marketing-companies/:id",
        "/logout",
      ]}
    >
      <AuthVerify />
      <MainLayout>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <Route index path="/dashboard" component={DashboardDefault} />

            <Route path="/create-booking" component={Createbooking} />
            <Route path="/view-booking" component={TableViewBooking} />
            <Route path="/booking/:id" component={SingleBooking} />
            <Route path="/my-bookings" component={UsersBookings} />
            <Route path="/view-inprogress-booking" component={InProgressBookings} /> 
            
            <Route path="/booking-deadline-tracker" component={DeadlineTracker} />

            <Route path="/add-customer" component={AddCustomer} />
            <Route path="/view-customer" component={ViewCustomer} />
            <Route path="/customer-detail/:id" component={ViewDetailCustomer} />

            <Route path="/add-shippingline" component={AddShippingLine} />
            <Route path="/view-shippingline" component={ViewShippingLine} />
             
            <Route path="/add-loadingsupplier" component={AddLoadingSupplier} />
            <Route path="/view-loadingsupplier" component={ViewLoadingSupplier} />

            <Route path="/userdetail/:id" component={ViewUserDetails} />
            <Route path="/view-user" component={ViewUsers} />
            <Route path="/add-user" component={AddUsers} />


            <Route path="/my-notifications" component={MyNotifications} />
            
            <Route path="/invoices" component={ViewInvoices} />
            <Route path="/due-invoices" component={DueInvoices} />

            <Route path="/submit-mrn" component={SubmitMrn} />
            <Route path="/completed-mrn" component={CompletedMrn} />
            

            <Route path="/add-customeagent" component={AddCustomAgent} />
            <Route path="/view-customeagent" component={ViewCustomAgents} />

            <Route path="/add-transportationagent" component={AddTransporterAgent} />
            <Route path="/view-transportationagent" component={ViewTransporterAgents} />

            <Route path="/email-marketing-companies" component={EmailMarketingCompanies} /> 
            <Route path="/send-email-quotes" component={EmailMarketingSendQuotes} /> 
            <Route path="/view-marketing-companies/:id" component={ViewEmailMarketingCompany} /> 

            <Route path="/logout" component={LogoutScreen} />
          </AuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
