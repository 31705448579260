import { faBook, faBookOpen, faBookSkull, faContactCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const BookingRoutes = (props) => {
  const userAccount = useSelector((state) => state.account);
  return (
    <>
      <div className="menu-item pt-5">
        <div className="menu-content">
          <span className="menu-heading fw-bold text-uppercase fs-7">
            Booking Management
          </span>
        </div>
      </div>

      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/create-booking")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
            <span className="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="currentColor"></path>
              <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path>
            </svg>
            </span>
          </span>
          <Link to="/create-booking">
            <span className="menu-title">Create Booking</span>{" "}
          </Link>
        </span>
      </div>

    

      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/view-inprogress-booking")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
          <FontAwesomeIcon
                      icon={faBookOpen}
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color:"#504a6e",
                        marginLeft:"5px"
                      }}
                    />
          </span>
          <Link to="/view-inprogress-booking" onClick={()=>{
             localStorage.removeItem("inprogresspagefilter");
          }}>
            <span className="menu-title">In Progress Bookings </span>{" "}
          </Link>
        </span>
      </div>
 

    

      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/view-booking")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
          <FontAwesomeIcon
                      icon={faBook}
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color:"#504a6e",
                        marginLeft:"5px"
                      }}
                    />
          </span>
          <Link to="/view-booking">
            <span className="menu-title">View All Bookings </span>{" "}
          </Link>
        </span>
      </div>
      {
      (userAccount?.user?.userRole === "ADMIN1" ||
      userAccount?.user?.userRole === "BOOKING_USER")?<div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className="menu-link">
          <span className="menu-icon">
          <FontAwesomeIcon
                      icon={faContactCard}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        color:"#504a6e",
                        marginLeft:"5px"
                      }}
                    />
          </span>
          {
            (userAccount?.user?.userRole === "ADMIN")?<Link to="/my-bookings">
            <span className="menu-title">All Task's Bookings </span>{" "}
          </Link>:<Link to="/my-bookings">
            <span className="menu-title">My Task's Bookings </span>{" "}
          </Link>
          }
          
        </span>
      </div>:null
      }
    </>
  );
};

export default BookingRoutes;
