import {
  ACCOUNT_INITIALIZE,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_LOADING,
  LOGOUT,
} from "../actions";

export const initialState = {
  token: "",
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  isLoading: false,
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACCOUNT_INITIALIZE: {
      const { isLoggedIn, user, token } = action.payload;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        token,
        user,
        isLoading: false,
      };
    }
    case LOGIN: {
      const { user, token } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        user,
        token,
        isLoading: false,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        token: "",
        user: null,
        isLoading: false,
      };
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        isLoggedIn: false,
        token: "",
        user: null,
        isLoading: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
