import axios from "axios";
import config from "../config";
import { LOGIN, LOGOUT } from "../store/actions";
import Cookies from "js-cookie";

export const authLogin = async (data) => {
  return await new Promise((resolve, reject) => {
    axios
      .post(config.API_SERVER + "auth/login", data)
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validateAuthToken = async (dispatcher, data) => {
  const appHeader = {
    Authorization: data,
  };

  axios
    .post(config.API_SERVER + "auth/verify", data, {
      headers: appHeader,
    })
    .then((data) => {
      console.log("validateAuthToken", data);
      if (!data.data) {
        dispatcher({
          type: LOGOUT,
          payload: {},
        });
      }
    })
    .catch((error) => {
      dispatcher({
        type: LOGOUT,
        payload: {},
      });
    });
};
