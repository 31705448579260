import { Link } from "react-router-dom";
const OtherRoutes = (props) => {
  return (
    <div
      data-kt-menu-trigger="click"
      className="menu-item here show menu-accordion"
    >
      <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/dashboard")?"menu-link active":"menu-link"}>
        <span className="menu-icon">
          <span className="svg-icon svg-icon-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2"
                y="2"
                width="9"
                height="9"
                rx="2"
                fill="currentColor"
              />
              <rect
                opacity="0.3"
                x="13"
                y="2"
                width="9"
                height="9"
                rx="2"
                fill="currentColor"
              />
              <rect
                opacity="0.3"
                x="13"
                y="13"
                width="9"
                height="9"
                rx="2"
                fill="currentColor"
              />
              <rect
                opacity="0.3"
                x="2"
                y="13"
                width="9"
                height="9"
                rx="2"
                fill="currentColor"
              />
            </svg>
          </span>
        </span>
        <Link to="/dashboard" onClick={()=>{
           localStorage.removeItem("dashboardfilter");
        }}>
          <span className="menu-title">Dashboards</span>{" "}
        </Link>
      </span>
    </div>
  );
};

export default OtherRoutes;
