import {
  SUPPLIER_ALL_REQUEST_LOADING,
  SUPPLIER_ALL_REQUEST_DATA,
  SUPPLIER_ALL_REQUEST_FAILED,
} from "../actions/supplierActions";

export const initialState = {
  supplierAllRequestLoading: false,
  supplierAllRequestData: [],
  supplierAllRequestFailed: false,
};

//-----------------------|| SUPPLIER REDUCER ||-----------------------//

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_ALL_REQUEST_LOADING: {
      return {
        ...state,
        supplierAllRequestLoading: true,
      };
    }
    case SUPPLIER_ALL_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        supplierAllRequestLoading: false,
        supplierAllRequestData: data,
      };
    }
    case SUPPLIER_ALL_REQUEST_FAILED: {
      return {
        ...state,
        supplierAllRequestLoading: false,
        supplierAllRequestData: [],
        supplierAllRequestFailed: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default supplierReducer;
