import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// reducer import
import accountReducer from "./accountReducer";
import supplierReducer from "./supplierReducer";
import bookingReducer from "./bookingReducer";
import customerReducer from "./customerReducer";
import userReducer from "./userReducer";
import companyReducer from "./companyReducer";
import dashboardReducer from "./dashboardReducer";
import invoiceReducer from "./invoiceReducer";
import notificationReducer from "./notificationReducer";
import mrnReducer from "./mrnReducer";
import customeAgentReducer from "./customAgentReducer";
import transporterAgentReducer from "./transporterAgentReducer";
import oceanportsReducer from "./oceanportsReducer";
import emailMarketingReducer from "./emailMarketingReducer";
import hsCodeReducer from "./hsCodeReducer";
import loadingSupplierReducer from "./loadingSupplierReducer";
//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
  account: persistReducer(
    {
      key: "account",
      storage,
      keyPrefix: "rsquareff-",
    },
    accountReducer
  ),
  supplierData: supplierReducer,
  bookingData: bookingReducer,
  customerData: customerReducer,
  userData: userReducer,
  companyData: companyReducer,
  dashboardData: dashboardReducer,
  invoiceData:invoiceReducer,
  notificationData:notificationReducer,
  mrnData:mrnReducer,
  customAgentData:customeAgentReducer,
  transporterAgentData:transporterAgentReducer,
  oceanportsData:oceanportsReducer,
  emailMarketingData:emailMarketingReducer,
  hsCodeReducerData:hsCodeReducer,
  loadingSupplierReducerData:loadingSupplierReducer
});

export default reducer;
