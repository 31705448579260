import {
    EMAIL_MARKETING_COMPANIES_DATA_FAILED,
    EMAIL_MARKETING_COMPANIES_DATA_VALUES,
    EMAIL_MARKETING_COMPANIES_LOADING
  } from "../actions/emailMarketingActions";
  
  export const initialState = {
    emailMarketingCompaniesLoading: false,
    emailMarketingCompaniesData: [],
    emailMarketingCompaniesFailed: false,
  };
  
  //-----------------------|| Email Marketing Companies REDUCER ||-----------------------//
  
  const emailMarketingReducer = (state = initialState, action) => {
    switch (action.type) {
      case EMAIL_MARKETING_COMPANIES_LOADING: {
        return {
          ...state,
          emailMarketingCompaniesLoading: true,
        };
      }
      case EMAIL_MARKETING_COMPANIES_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          emailMarketingCompaniesLoading: false,
          emailMarketingCompaniesData: data,
        };
      }
      case EMAIL_MARKETING_COMPANIES_DATA_FAILED: {
        return {
          ...state,
          emailMarketingCompaniesLoading: false,
          emailMarketingCompaniesData: [],
          emailMarketingCompaniesFailed: true,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default emailMarketingReducer;

  