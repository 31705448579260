import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import TextTransition, { presets } from "react-text-transition";
import Header from "./Header"; 
import userA from "./../../assets/images/users/lg-user1.jpg";
import userB from "./../../assets/images/users/lg-user2.jpg";
import userC from "./../../assets/images/users/lg-user3.jpg";
import userD from "./../../assets/images/users/lg-user4.jpg";
import userE from "./../../assets/images/users/lg-user5.jpg";
import config from "../../config";
// const BootStrapCss = React.lazy(()=>import('../../../node_modules/bootstrap/dist/css/bootstrap.min.css'));
//-----------------------|| MINIMAL LAYOUT ||-----------------------//
const TEXTS = ["Rsquareff", "GreenWaterff"];

const MinimalLayout = (props) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
    <div className="auth-page-wrapper py-5 position-relative d-flex align-items-center justify-content-center min-vh-100" style={{backgroundColor:config.websiteLoginBackgroudColor}}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="card mb-0">
            <div className="row g-0 align-items-center">
              <div className="col-xxl-6">
                <div className="card login-auth-card h-100 border-0 shadow-none d-none d-sm-block mb-0" style={{backgroundColor:"black"}}>
                  <div className="card-body py-5 d-flex justify-content-between flex-column">
                    <div className="text-center">
                        <h3 className="text-white">Start your journey with us.</h3>
                        <p className="text-white opacity-75 fs-base">It brings together your tasks, projects, timelines, files and more</p>
                    </div>
                    <div className="auth-effect-main my-5 position-relative rounded-circle d-flex align-items-center justify-content-center mx-auto" style={{width: "444px",height: "444px",border: "1px solid #ccc"}}>
                      <div className="effect-circle-1 position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center" style={{width: "396px",height: "396px",border: "1px solid #ccc"}}>
                          <div className="effect-circle-2 position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center" style={{width: "348px",height: "348px",border: "1px solid #ccc"}}>
                              <div className="effect-circle-3 mx-auto rounded-circle position-relative text-white fs-4xl d-flex align-items-center justify-content-center" style={{width: "300px",height: "300px",border: "1px solid #ccc",fontSize:"1.375rem"}}>
                                  Welcome to <span className="text-primary ms-1">Rsquareff</span>
                              </div>
                          </div>
                      </div>
                      <ul className="auth-user-list list-unstyled">
                          <li>
                              <div className="avatar-sm d-inline-block" style={{height:"3rem",width:"3rem"}}>
                                  <div className="login-avatar-title bg-white shadow-lg overflow-hidden rounded-circle"> 
                                    <img src={userA} className="img-fluid" alt="" />
                                  </div>
                              </div>
                          </li>
                          <li>
                              <div className="avatar-sm d-inline-block" style={{height:"3rem",width:"3rem"}}>
                                  <div className="login-avatar-title bg-white shadow-lg overflow-hidden rounded-circle"> 
                                  <img src={userB} className="img-fluid" alt=""/>
                                  </div>
                              </div>
                          </li>
                          <li>
                              <div className="avatar-sm d-inline-block" style={{height:"3rem",width:"3rem"}}>
                                  <div className="login-avatar-title bg-white shadow-lg overflow-hidden rounded-circle"> 
                                  <img src={userC} className="img-fluid" alt="" />
                                  </div>
                              </div>
                          </li>
                          <li>
                              <div className="avatar-sm d-inline-block" style={{height:"3rem",width:"3rem"}}>
                                  <div className="login-avatar-title bg-white shadow-lg overflow-hidden rounded-circle"> 
                                  <img src={userD} className="img-fluid" alt=""/>
                                  </div>
                              </div>
                          </li>
                          <li>
                              <div className="avatar-sm d-inline-block" style={{height:"3rem",width:"3rem"}}>
                                  <div className="login-avatar-title bg-white shadow-lg overflow-hidden rounded-circle"> 
                                  <img src={userE} className="img-fluid" alt="" />
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-5">
              {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
    </>
  );
};

MinimalLayout.propTypes = {
  children: PropTypes.node,
};

export default MinimalLayout;
