export const INVOICE_NOT_PAID_DATA_LOADING = "INVOICE_NOT_PAID_DATA_LOADING";
export const INVOICE_NOT_PAID_DATA_VALUES = "INVOICE_NOT_PAID_DATA_VALUES";
export const INVOICE_NOT_PAID_DATA_FAILED = "INVOICE_NOT_PAID_DATA_FAILED";

export const INVOICE_PAID_DATA_LOADING = "INVOICE_PAID_DATA_LOADING";
export const INVOICE_PAID_DATA_VALUES = "INVOICE_PAID_DATA_VALUES";
export const INVOICE_PAID_DATA_FAILED = "INVOICE_PAID_DATA_FAILED";

export const INVOICE_CANCELED_DATA_LOADING = "INVOICE_CANCELED_DATA_LOADING";
export const INVOICE_CANCELED_DATA_VALUES = "INVOICE_CANCELED_DATA_VALUES";
export const INVOICE_CANCELED_DATA_FAILED = "INVOICE_CANCELED_DATA_FAILED";

export const INVOICE_DISPUTED_DATA_LOADING = "INVOICE_DISPUTED_DATA_LOADING";
export const INVOICE_DISPUTED_DATA_VALUES = "INVOICE_DISPUTED_DATA_VALUES";
export const INVOICE_DISPUTED_DATA_FAILED = "INVOICE_DISPUTED_DATA_FAILED";
 
