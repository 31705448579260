import FooterSection from "./FooterSection";
import HeaderSection from "./HeaderSection";
import AccountRoutes from "./MidSection/AccountsRoutes";
import BookingRoutes from "./MidSection/BookingRoutes";
import OtherRoutes from "./MidSection/OtherRoutes";
import SalesRoutes from "./MidSection/SalesRoutes";
import CustomerRoutes from "./MidSection/CustomersRoutes";
import ShippingLineRoutes from "./MidSection/ShippingLineRoutes";
import UsersRoutes from "./MidSection/UserRoutes";
import InvoiceRoutes from "./MidSection/InvoiceRoutes";
import MrnRoutes from "./MidSection/MrnRoutes";
import CustomAgentsRoutes from "./MidSection/CustomAgentsRoutes";
import TransportationRoutes from "./MidSection/TransportationRoutes";
import {useLocation} from "react-router-dom"; 
import { useEffect, useState } from "react";
import config from "../../../config"; 
import EmailMarketingRoutes from "./MidSection/EmailMarketingRoutes";
import DeadLinesRoutes from "./MidSection/DeadlineRoutes";
import LoadingSupplierRoutes from "./MidSection/LoadingSupplierRoutes";

const Sidebar = () => {
  const [getSeletedPageName,setSelectedPageName] = useState("");
  const location = useLocation();

  useEffect(()=>{
    setSelectedPageName(location.pathname); 
  },[location.pathname])
  return (
    <div
      id="kt_app_sidebar"
      className="app-sidebar flex-column"
       style={{backgroundColor:config.websiteSidebarBackgroundColor}} //#1e1541
    >
      <HeaderSection />
      <div
        className="app-sidebar-menu flex-column-fluid"
        style={{ maxHeight: "85%", overflowY: "scroll", overflowX: "hidden" }}
      >
        <div
          id="kt_app_sidebar_menu_wrapper"
          className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
        >
          <div
            className="menu menu-column menu-rounded menu-sub-indention px-3"
            id="#kt_app_sidebar_menu" 
          >
            <OtherRoutes 
             isMainRouteSelected={(getSeletedPageName === "/dashboard")?true:false}
             getSeletedPageName={getSeletedPageName} 
            />

            <BookingRoutes 
              isMainRouteSelected={(getSeletedPageName === "/create-booking" || getSeletedPageName === "/view-inprogress-booking" || getSeletedPageName === "/view-booking")?true:false}
              getSeletedPageName={getSeletedPageName}
            />
            <DeadLinesRoutes
             isMainRouteSelected={(getSeletedPageName === "/booking-deadline-tracker")?true:false}
             getSeletedPageName={getSeletedPageName}
            />
            <MrnRoutes 
            isMainRouteSelected={(getSeletedPageName === "/submit-mrn" || getSeletedPageName === "/completed-mrn")?true:false}
            getSeletedPageName={getSeletedPageName} 
            />
            <InvoiceRoutes 
            isMainRouteSelected={(getSeletedPageName === "/invoices" || getSeletedPageName === "/due-invoices")?true:false}
            getSeletedPageName={getSeletedPageName} 
            />
            <CustomerRoutes 
            isMainRouteSelected={(getSeletedPageName === "/add-customer" || getSeletedPageName === "/view-customer")?true:false}
            getSeletedPageName={getSeletedPageName} 
            />
            <ShippingLineRoutes 
            isMainRouteSelected={(getSeletedPageName === "/add-shippingline" || getSeletedPageName === "/view-shippingline")?true:false}
            getSeletedPageName={getSeletedPageName} 
            />
            <LoadingSupplierRoutes
             isMainRouteSelected={(getSeletedPageName === "/add-loadingsupplier" || getSeletedPageName === "/view-loadingsupplier")?true:false}
             getSeletedPageName={getSeletedPageName} 
            />
            <CustomAgentsRoutes 
            isMainRouteSelected={(getSeletedPageName === "/add-customeagent" || getSeletedPageName === "/view-customeagent")?true:false}
            getSeletedPageName={getSeletedPageName} 
            />
            <TransportationRoutes 
            isMainRouteSelected={(getSeletedPageName === "/add-transportationagent" || getSeletedPageName === "/view-transportationagent")?true:false}
            getSeletedPageName={getSeletedPageName} 
            />
            <EmailMarketingRoutes
               isMainRouteSelected={(getSeletedPageName === "/email-marketing-companies" || getSeletedPageName === "/send-email-quotes")?true:false}
               getSeletedPageName={getSeletedPageName} 
            />
            <UsersRoutes 
            isMainRouteSelected={(getSeletedPageName === "/add-user" || getSeletedPageName === "/view-user")?true:false}
            getSeletedPageName={getSeletedPageName} 
            />

            {/* <SalesRoutes />  
                            <AccountRoutes />  */}
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default Sidebar;
