import { faBook, faBookOpen, faBookSkull, faContactCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const MrnRoutes = (props) => {
  const userAccount = useSelector((state) => state.account);
  return (
    <>
      <div className="menu-item pt-5">
        <div className="menu-content">
          <span className="menu-heading fw-bold text-uppercase fs-7">
            MRN & VGM Management
          </span>
        </div>
      </div>

      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/submit-mrn")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
            <span className="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="currentColor"></path>
              <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path>
            </svg>
            </span>
          </span>
          <Link to="/submit-mrn">
            <span className="menu-title">In Progress MRN & VGM</span>{" "}
          </Link>
        </span>
      </div>
 
      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/completed-mrn")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
          <FontAwesomeIcon
                      icon={faBook}
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color:"#504a6e",
                        marginLeft:"5px"
                      }}
                    />
          </span>
          <Link to="/completed-mrn">
            <span className="menu-title">Completed MRN & VGM</span>{" "}
          </Link>
        </span>
      </div>

    </>
  );
};

export default MrnRoutes;
