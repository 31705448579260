import axios from "axios";
import config from "../config";
import {
  USER_COMPANY_REQUEST_LOADING,
  USER_COMPANY_REQUEST_DATA,
  USER_COMPANY_REQUEST_FAILED,
} from "../store/actions/companyActions";

export const getUserCompanyData = async (dispatcher, header, id) => {
  dispatcher({
    type: USER_COMPANY_REQUEST_LOADING,
    payload: {
      isLoading: true,
    },
  });

  axios
    .get(config.API_SERVER + "company/" + id, {
      headers: header,
    })
    .then((data) => {
      dispatcher({
        type: USER_COMPANY_REQUEST_DATA,
        payload: {
          data: data.data,
        },
      });
    })
    .catch((error) => {
      dispatcher({
        type: USER_COMPANY_REQUEST_FAILED,
        payload: {},
      });
    });
};
