export const BOOKING_LISTING_ALL_REQUEST_LOADING =
  "BOOKING_LISTING_ALL_REQUEST_LOADING";
export const BOOKING_LISTING_ALL_REQUEST_DATA =
  "BOOKING_LISTING_ALL_REQUEST_DATA";
export const BOOKING_LISTING_ALL_REQUEST_FAILED =
  "BOOKING_LISTING_ALL_REQUEST_FAILED";

export const BOOKING_LISTING_IN_PROGRESS_REQUEST_LOADING =
  "BOOKING_LISTING_IN_PROGRESS_REQUEST_LOADING";
export const BOOKING_LISTING_IN_PROGRESS_REQUEST_DATA =
  "BOOKING_LISTING_IN_PROGRESS_REQUEST_DATA";
export const BOOKING_LISTING_IN_PROGRESS_REQUEST_FAILED =
  "BOOKING_LISTING_IN_PROGRESS_REQUEST_FAILED";

export const BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_LOADING =
  "BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_LOADING";
export const BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_DATA =
  "BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_DATA";
export const BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_FAILED =
  "BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_FAILED";

export const BOOKING_LISTING_COMPLETED_REQUEST_LOADING =
  "BOOKING_LISTING_COMPLETED_REQUEST_LOADING";
export const BOOKING_LISTING_COMPLETED_REQUEST_DATA =
  "BOOKING_LISTING_COMPLETED_REQUEST_DATA";
export const BOOKING_LISTING_COMPLETED_REQUEST_FAILED =
  "BOOKING_LISTING_COMPLETED_REQUEST_FAILED";

export const BOOKING_LISTING_CANCELED_REQUEST_LOADING =
  "BOOKING_LISTING_CANCELED_REQUEST_LOADING";
export const BOOKING_LISTING_CANCELED_REQUEST_DATA =
  "BOOKING_LISTING_CANCELED_REQUEST_DATA";
export const BOOKING_LISTING_CANCELED_REQUEST_FAILED =
  "BOOKING_LISTING_CANCELED_REQUEST_FAILED";


export const BOOKING_LISTING_DISPUTE_REQUEST_LOADING =
  "BOOKING_LISTING_DISPUTE_REQUEST_LOADING";
export const BOOKING_LISTING_DISPUTE_REQUEST_DATA =
  "BOOKING_LISTING_DISPUTE_REQUEST_DATA";
export const BOOKING_LISTING_DISPUTE_REQUEST_FAILED =
  "BOOKING_LISTING_DISPUTE_REQUEST_FAILED";


export const BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_LOADING =
  "BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_LOADING";
export const BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_DATA =
  "BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_DATA";
export const BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_FAILED =
  "BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_FAILED";