 
import { INVOICE_CANCELED_DATA_FAILED,INVOICE_CANCELED_DATA_LOADING,INVOICE_CANCELED_DATA_VALUES,
        INVOICE_DISPUTED_DATA_FAILED,
        INVOICE_DISPUTED_DATA_LOADING,
        INVOICE_DISPUTED_DATA_VALUES,
        INVOICE_NOT_PAID_DATA_FAILED,INVOICE_NOT_PAID_DATA_LOADING,INVOICE_NOT_PAID_DATA_VALUES,
        INVOICE_PAID_DATA_FAILED,INVOICE_PAID_DATA_LOADING,INVOICE_PAID_DATA_VALUES
} from "../actions/invoiceActions";
  
  export const initialState = {
    invoiceNotPaidDataLoading: false,
    invoiceNotPaidDataValues: [],
    invoiceNotPaidDataFailed: false,
    invoicePaidDataLoading: false,
    invoicePaidDataValues: [],
    invoicePaidDataFailed: false,
    invoiceCanceledDataLoading: false,
    invoiceCanceledDataValues: [],
    invoiceCanceledDataFailed: false,
    invoiceDisputedDataLoading: false,
    invoiceDisputedDataValues: [],
    invoiceDisputedDataFailed: false,
  };
  
  //-----------------------|| INVOICE REDUCER ||-----------------------//
  
  const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
      case INVOICE_NOT_PAID_DATA_LOADING: {
        return {
          ...state,
          invoiceNotPaidDataLoading: true,
        };
      }
      case INVOICE_NOT_PAID_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          invoiceNotPaidDataLoading: false,
          invoiceNotPaidDataValues: data,
        };
      }
      case INVOICE_NOT_PAID_DATA_FAILED: {
        return {
          ...state,
          invoiceNotPaidDataLoading: false,
          invoiceNotPaidDataValues: [],
          invoiceNotPaidDataFailed: true,
        };
      }
      case INVOICE_PAID_DATA_LOADING: {
        return {
          ...state,
          invoicePaidDataLoading: true,
        };
      }
      case INVOICE_PAID_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          invoicePaidDataLoading: false,
          invoicePaidDataValues: data,
        };
      }
      case INVOICE_PAID_DATA_FAILED: {
        return {
          ...state,
          invoicePaidDataLoading: false,
          invoicePaidDataValues: [],
          invoicePaidDataFailed: true,
        };
      }
      case INVOICE_CANCELED_DATA_LOADING: {
        return {
          ...state,
          invoiceCanceledDataLoading: true,
        };
      }
      case INVOICE_CANCELED_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          invoiceCanceledDataLoading: false,
          invoiceCanceledDataValues: data,
        };
      }
      case INVOICE_CANCELED_DATA_FAILED: {
        return {
          ...state,
          invoiceCanceledDataLoading: false,
          invoiceCanceledDataValues: [],
          invoiceCanceledDataFailed: true,
        };
      }
      case INVOICE_DISPUTED_DATA_LOADING: {
        return {
          ...state,
          invoiceDisputedDataLoading: true,
        };
      }
      case INVOICE_DISPUTED_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          invoiceDisputedDataLoading: false,
          invoiceDisputedDataValues: data,
        };
      }
      case INVOICE_DISPUTED_DATA_FAILED: {
        return {
          ...state,
          invoiceDisputedDataLoading: false,
          invoiceDisputedDataValues: [],
          invoiceDisputedDataFailed: true,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default invoiceReducer;
  