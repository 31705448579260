import {
    CUSTOM_AGENTS_ALL_REQUEST_DATA,
    CUSTOM_AGENTS_ALL_REQUEST_FAILED,
    CUSTOM_AGENTS_ALL_REQUEST_LOADING,
  } from "../actions/customAgentActions";
  
  export const initialState = {
    customAgentsAllRequestLoading: false,
    customAgentsAllRequestData: [],
    customAgentsAllRequestFailed: false,
  };
  
  //-----------------------|| Custom Agents REDUCER ||-----------------------//
  
  const customeAgentReducer = (state = initialState, action) => {
    switch (action.type) {
      case CUSTOM_AGENTS_ALL_REQUEST_LOADING: {
        return {
          ...state,
          customAgentsAllRequestLoading: true,
        };
      }
      case CUSTOM_AGENTS_ALL_REQUEST_DATA: {
        const { data } = action.payload;
        return {
          ...state,
          customAgentsAllRequestLoading: false,
          customAgentsAllRequestData: data,
        };
      }
      case CUSTOM_AGENTS_ALL_REQUEST_FAILED: {
        return {
          ...state,
          customAgentsAllRequestLoading: false,
          customAgentsAllRequestData: [],
          customAgentsAllRequestFailed: true,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default customeAgentReducer; 