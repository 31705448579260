import React, { useEffect } from "react";
import Routes from "./routes";
import NavigationScroll from "./layout/NavigationScroll";
import "./assets/css/AuthGuard/style.bundle.css";
import "./assets/css/GuestGuard.css";
import { GlobalDebug } from "./utils/route-guard/GlobalDebug";
const App = () => {
  
  useEffect(() => {
    (process.env.REACT_APP_ENV === "prod") &&
      GlobalDebug(false);
  }, []);

  return (
    <NavigationScroll>
      <Routes />
    </NavigationScroll>
  );
};
export default App;
