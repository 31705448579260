import {
    TRANSPORTER_AGENTS_ALL_REQUEST_DATA,
    TRANSPORTER_AGENTS_ALL_REQUEST_FAILED,
    TRANSPORTER_AGENTS_ALL_REQUEST_LOADING,
  } from "../actions/transporterAgentActions";
  
  export const initialState = {
    transporterAgentsAllRequestLoading: false,
    transporterAgentsAllRequestData: [],
    transporterAgentsAllRequestFailed: false,
  };
  
  //-----------------------||  Transporter Agents REDUCER ||-----------------------//
  
  const transporterAgentReducer = (state = initialState, action) => {
    switch (action.type) {
      case TRANSPORTER_AGENTS_ALL_REQUEST_LOADING: {
        return {
          ...state,
          transporterAgentsAllRequestLoading: true,
        };
      }
      case TRANSPORTER_AGENTS_ALL_REQUEST_DATA: {
        const { data } = action.payload;
        return {
          ...state,
          transporterAgentsAllRequestLoading: false,
          transporterAgentsAllRequestData: data,
        };
      }
      case TRANSPORTER_AGENTS_ALL_REQUEST_FAILED: {
        return {
          ...state,
          transporterAgentsAllRequestLoading: false,
          transporterAgentsAllRequestData: [],
          transporterAgentsAllRequestFailed: true,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default transporterAgentReducer; 