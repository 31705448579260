import PropTypes from "prop-types";
import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header"; 
const AuthGuardCssData = React.lazy(() =>
  import("./../../components/AuthGuardCss")
);
const MainLayout = (props) => {
  return (
    <>
      <React.Suspense fallback={<></>}>
        <div
          data-kt-name="metronic"
          id="kt_app_body"
          data-kt-app-layout="dark-sidebar"
          data-kt-app-header-fixed="true"
          data-kt-app-sidebar-enabled="true"
          data-kt-app-sidebar-fixed="true"
          data-kt-app-sidebar-hoverable="true"
          data-kt-app-sidebar-push-header="true"
          data-kt-app-sidebar-push-toolbar="true"
          data-kt-app-sidebar-push-footer="true"
          data-kt-app-toolbar-enabled="true"
          className="app-default"
        >
          <div
            className="d-flex flex-column flex-root app-root"
            id="kt_app_root"
          >
            <div
              className="app-page flex-column flex-column-fluid"
              id="kt_app_page"
            >
              <Header />
              <div
                className="app-wrapper flex-column flex-row-fluid"
                id="kt_app_wrapper"
                style={{backgroundColor:"rgb(239, 239, 240)"}}
              >
                <Sidebar />
                <div
                  className="app-main flex-column flex-row-fluid"
                  id="kt_app_main"
                >
                  <div className="d-flex flex-column flex-column-fluid">
                    {props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </React.Suspense>
    </>
  );
};

export default MainLayout;
