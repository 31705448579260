import {
   HS_CODE_DATA_FAILED,
   HS_CODE_DATA_LOADING,
   HS_CODE_DATA_VALUES
  } from "../actions/hsCodeActions";
  
  export const initialState = {
    hsCodeLoading: false,
    hsCodeData: [],
    hsCodeFailed: false,
  };
  
  //-----------------------|| HS Code REDUCER ||-----------------------//
  
  const hsCodeReducer = (state = initialState, action) => {
    switch (action.type) {
      case HS_CODE_DATA_LOADING: {
        return {
          ...state,
          hsCodeLoading: true,
        };
      }
      case HS_CODE_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          hsCodeLoading: false,
          hsCodeData: data,
        };
      }
      case HS_CODE_DATA_FAILED: {
        return {
          ...state,
          hsCodeLoading: false,
          hsCodeData: [],
          hsCodeFailed: true,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default hsCodeReducer;

  