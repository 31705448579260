import axios from "axios";
import config from "../config";
import { NOTIFICATION_COUNT_DATA_FAILED, NOTIFICATION_COUNT_DATA_VALUES, NOTIFICATION_COUNT_LOADING } from "../store/actions/notificationActions";

// export const getAllUnreadNotification = async (appHeader) => {
//     return await new Promise((resolve, reject) => {
//         axios
//         .get(config.API_SERVER + "notification/unread",{
//             headers: appHeader,
//         })
//         .then((data) => {
//             resolve(data);
//         })
//         .catch((error) => {
//             reject(error);
//         });
//     });
//     };

export const getAllUnreadNotificationData = async (dispatcher, header) => {
    dispatcher({
        type: NOTIFICATION_COUNT_LOADING,
        payload: {
        isLoading: true,
        },
    });
    
    axios
        .get(config.API_SERVER + "notification/unread", {
        headers: header,
        })
        .then((data) => {
        dispatcher({
            type: NOTIFICATION_COUNT_DATA_VALUES,
            payload: {
            data: data.data,
            },
        });
        })
        .catch((error) => {
        dispatcher({
            type: NOTIFICATION_COUNT_DATA_FAILED,
            payload: {},
        });
        });
    };

        
export const getAllNotificationCount = async (appHeader) => {
    return await new Promise((resolve, reject) => {
        axios
        .get(config.API_SERVER + "notification/allcount",{
            headers: appHeader,
        })
        .then((data) => {
            resolve(data.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
    };
    

export const getNotificationByStatus = async (data, appHeader) => {
    return await new Promise((resolve, reject) => {
        axios
        .post(config.API_SERVER + "notification/getbystatus", data, { headers: appHeader })
        .then((data) => {
            resolve(data.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
    };
    

        
export const markAsReadNotification = async (data, appHeader) => {
    return await new Promise((resolve, reject) => {
        axios
        .post(config.API_SERVER + "notification/markasread", data, { headers: appHeader })
        .then((data) => {
            resolve(data.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
    };

    export const markAsImportantNotification = async (data, appHeader) => {
        return await new Promise((resolve, reject) => {
            axios
            .post(config.API_SERVER + "notification/markasimportant", data, { headers: appHeader })
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
        };
    
    