import {
    LOADING_SUPPLIER_ALL_REQUEST_DATA,
    LOADING_SUPPLIER_ALL_REQUEST_FAILED,
    LOADING_SUPPLIER_ALL_REQUEST_LOADING,
  } from "../actions/loadingSupplierActions";
  
  export const initialState = {
    loadingSupplierAllRequestLoading: false,
    loadingSupplierAllRequestData: [],
    loadingSupplierAllRequestFailed: false,
  };
  
  //-----------------------|| LOADING SUPPLIER REDUCER ||-----------------------//
  
  const loadingSupplierReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOADING_SUPPLIER_ALL_REQUEST_LOADING: {
        return {
          ...state,
          loadingSupplierAllRequestLoading: true,
        };
      }
      case LOADING_SUPPLIER_ALL_REQUEST_DATA: {
        const { data } = action.payload;
        return {
          ...state,
          loadingSupplierAllRequestLoading: false,
          loadingSupplierAllRequestData: data,
        };
      }
      case LOADING_SUPPLIER_ALL_REQUEST_FAILED: {
        return {
          ...state,
          loadingSupplierAllRequestLoading: false,
          loadingSupplierAllRequestData: [],
          loadingSupplierAllRequestFailed: true,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default loadingSupplierReducer; 