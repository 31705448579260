import { Link } from "react-router-dom";

const SuppliersRoutes = (props) => {
  return (
    <>
      <div className="menu-item pt-5">
        <div className="menu-content">
          <span className="menu-heading fw-bold text-uppercase fs-7">
            Shipping Line Management
          </span>
        </div>
      </div>

      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/add-supplier")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                  fill="currentColor"
                />
                <rect
                  opacity="0.3"
                  x="8"
                  y="3"
                  width="8"
                  height="8"
                  rx="4"
                  fill="currentColor"
                />
              </svg>
            </span>
          </span>
          <Link to="/add-shippingline">
            <span className="menu-title">Add New Shipping Line</span>{" "}
          </Link>
        </span>
      </div>

      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/view-supplier")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"></path>
            <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"></path>
          </svg>
          </span>
          <Link to="/view-shippingline">
            <span className="menu-title">View All Shipping Line</span>{" "}
          </Link>
        </span>
      </div>
    </>
  );
};

export default SuppliersRoutes;
