import {
  CUSTOMER_ALL_REQUEST_LOADING,
  CUSTOMER_ALL_REQUEST_DATA,
  CUSTOMER_ALL_REQUEST_FAILED,
} from "../actions/customerActions";

export const initialState = {
  customerAllRequestLoading: false,
  customerAllRequestData: [],
  customerAllRequestFailed: false,
};

//-----------------------|| CUSTOMER REDUCER ||-----------------------//

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_ALL_REQUEST_LOADING: {
      return {
        ...state,
        customerAllRequestLoading: true,
      };
    }
    case CUSTOMER_ALL_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        customerAllRequestLoading: false,
        customerAllRequestData: data,
      };
    }
    case CUSTOMER_ALL_REQUEST_FAILED: {
      return {
        ...state,
        customerAllRequestLoading: false,
        customerAllRequestData: [],
        customerAllRequestFailed: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default customerReducer;
