import {
    MRN_DATA_FAILED,
    MRN_DATA_LOADING,
    MRN_DATA_VALUES,
    MRN_COMPLETED_DATA_FAILED,
    MRN_COMPLETED_DATA_LOADING,
    MRN_COMPLETED_DATA_VALUES
  } from "../actions/mrnActions";
  
  export const initialState = {
    mrnRequestLoading: false,
    mrnRequestData: [],
    mrnRequestFailed: false,
    mrnCompletedBookingsLoading: false,
    mrnCompletedBookingsData: [],
    mrnCompletedBookingsFailed: false,
  };
  
  //-----------------------|| COMPANY REDUCER ||-----------------------//
  
  const mrnReducer = (state = initialState, action) => {
    switch (action.type) {
      case MRN_DATA_LOADING: {
        return {
          ...state,
          mrnRequestLoading: true,
        };
      }
      case MRN_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          mrnRequestLoading: false,
          mrnRequestData: data,
        };
      }
      case MRN_DATA_FAILED: {
        return {
          ...state,
          mrnRequestLoading: false,
          mrnRequestData: [],
          mrnRequestFailed: true,
        };
      }
      case MRN_COMPLETED_DATA_LOADING: {
        return {
          ...state,
          mrnCompletedBookingsLoading: true,
        };
      }
      case MRN_COMPLETED_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          mrnCompletedBookingsLoading: false,
          mrnCompletedBookingsData: data,
        };
      }
      case MRN_COMPLETED_DATA_FAILED: {
        return {
          ...state,
          mrnCompletedBookingsLoading: false,
          mrnCompletedBookingsData: [],
          mrnCompletedBookingsFailed: true,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default mrnReducer;