import { NOTIFICATION_COUNT_LOADING,NOTIFICATION_COUNT_DATA_FAILED,NOTIFICATION_COUNT_DATA_VALUES } from "../actions/notificationActions";

export const initialState = {
    notificationCountLoading: false,
    notificationCountDataValues: [],
    notificationCountDataFailed: false
  };


  const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case NOTIFICATION_COUNT_LOADING: {
        return {
          ...state,
          notificationCountLoading: true,
        };
      }
      case NOTIFICATION_COUNT_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          notificationCountLoading: false,
          notificationCountDataValues: data,
        };
      }
      case NOTIFICATION_COUNT_DATA_FAILED: {
        return {
          ...state,
          notificationCountLoading: false,
          notificationCountDataValues: [],
          notificationCountDataFailed: true,
        };
      } 
      default: {
        return { ...state };
      }
    }
  };
  
  export default notificationReducer;
  
