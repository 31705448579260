import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateAuthToken } from "../../services/authServices";
const AuthVerify = () => {
  const dispatcher = useDispatch();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    account.token != "" && validateAuthToken(dispatcher, account.token);
  }, [account.token]);

  return <></>;
};

export default AuthVerify;
