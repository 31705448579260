import { useEffect, useState } from "react";
import { Link } from "react-router-dom"; 

const TransportationRoutes = (props) => { 
  const [getSelectedPage,setSelectedPage] = useState();  
  return(
        <>
        <div className="menu-item pt-5">
        <div className="menu-content">
          <span className="menu-heading fw-bold text-uppercase fs-7">
             Transportation Management
          </span>
        </div>
      </div>

      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/add-transportationagent")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                  fill="currentColor"
                />
                <rect
                  opacity="0.3"
                  x="8"
                  y="3"
                  width="8"
                  height="8"
                  rx="4"
                  fill="currentColor"
                />
              </svg>
            </span>
          </span>
          <Link to="/add-transportationagent">
            <span className="menu-title">Add New Transporter</span>
          </Link>
        </span>
      </div>

      <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
        <span className={(props.isMainRouteSelected && props.getSeletedPageName === "/view-transportationagent")?"menu-link active":"menu-link"}>
          <span className="menu-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7 8L7.49998 15.3L4.59999 20.3C3.49999 18.4 3.1 17.7 2.3 16.3C1.9 15.7 1.9 14.9 2.3 14.3L8.8 3L11.7 8Z" fill="currentColor"></path>
            <path opacity="0.3" d="M11.7 8L8.79999 3H13.4C14.1 3 14.8 3.4 15.2 4L20.6 13.3H14.8L11.7 8ZM7.49997 15.2L4.59998 20.2H17.6C18.3 20.2 19 19.8 19.4 19.2C20.2 17.7 20.6 17 21.7 15.2H7.49997Z" fill="currentColor"></path>
          </svg>
          </span>
          <Link to="/view-transportationagent">
            <span className="menu-title">View All Transporters</span>
          </Link>
        </span>
      </div> 
        </>
    )
}

export default TransportationRoutes;