import {
  BOOKING_LISTING_ALL_REQUEST_LOADING,
  BOOKING_LISTING_ALL_REQUEST_DATA,
  BOOKING_LISTING_ALL_REQUEST_FAILED,
  BOOKING_LISTING_IN_PROGRESS_REQUEST_LOADING,
  BOOKING_LISTING_IN_PROGRESS_REQUEST_DATA,
  BOOKING_LISTING_IN_PROGRESS_REQUEST_FAILED,
  BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_LOADING,
  BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_DATA,
  BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_FAILED,
  BOOKING_LISTING_COMPLETED_REQUEST_LOADING,
  BOOKING_LISTING_COMPLETED_REQUEST_DATA,
  BOOKING_LISTING_COMPLETED_REQUEST_FAILED,
  BOOKING_LISTING_CANCELED_REQUEST_LOADING,
  BOOKING_LISTING_CANCELED_REQUEST_DATA,
  BOOKING_LISTING_CANCELED_REQUEST_FAILED,
  BOOKING_LISTING_DISPUTE_REQUEST_DATA,
  BOOKING_LISTING_DISPUTE_REQUEST_FAILED,
  BOOKING_LISTING_DISPUTE_REQUEST_LOADING,
  BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_DATA,
  BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_FAILED,
  BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_LOADING
} from "../actions/bookingActions";

export const initialState = {
  bokkingListingAllRequestLoading: false,
  bokkingListingAllRequestData: [],
  bokkingListingAllRequestFailed: false,
  bokkingListingInProgressRequestLoading: false,
  bokkingListingInProgressRequestData: [],
  bokkingListingInProgressRequestFailed: false,
  bokkingListingShipmentPlanningRequestLoading: false,
  bokkingListingShipmentPlanningRequestData: [],
  bokkingListingShipmentPlanningRequestFailed: false,
  bokkingListingCompletedRequestLoading: false,
  bokkingListingCompletedRequestData: [],
  bokkingListingCompletedRequestFailed: false,
  bokkingListingCanceledRequestLoading: false,
  bokkingListingCanceledRequestData: [],
  bokkingListingCanceledRequestFailed: false,
  bokkingListingDisputeRequestLoading: false,
  bokkingListingDisputeRequestData: [],
  bokkingListingDisputeRequestFailed: false,
  bokkingUserListingInProgressRequestLoading: false,
  bokkingUserListingInProgressRequestData: [],
  bokkingUserListingInProgressRequestFailed: false,
};

//-----------------------|| BOOKING REDUCER ||-----------------------//

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_LISTING_ALL_REQUEST_LOADING: {
      return {
        ...state,
        bokkingListingAllRequestLoading: true,
      };
    }
    case BOOKING_LISTING_ALL_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        bokkingListingAllRequestLoading: false,
        bokkingListingAllRequestData: data,
      };
    }
    case BOOKING_LISTING_ALL_REQUEST_FAILED: {
      return {
        ...state,
        bokkingListingAllRequestLoading: false,
        bokkingListingAllRequestData: [],
        bokkingListingAllRequestFailed: true,
      };
    }
    case BOOKING_LISTING_IN_PROGRESS_REQUEST_LOADING: {
      return {
        ...state,
        bokkingListingInProgressRequestLoading: true,
      };
    }
    case BOOKING_LISTING_IN_PROGRESS_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        bokkingListingInProgressRequestLoading: false,
        bokkingListingInProgressRequestData: data,
      };
    }
    case BOOKING_LISTING_IN_PROGRESS_REQUEST_FAILED: {
      return {
        ...state,
        bokkingListingInProgressRequestLoading: false,
        bokkingListingInProgressRequestData: [],
        bokkingListingInProgressRequestFailed: true,
      };
    }
    case BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_LOADING: {
      return {
        ...state,
        bokkingListingShipmentPlanningRequestLoading: true,
      };
    }
    case BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        bokkingListingShipmentPlanningRequestLoading: false,
        bokkingListingShipmentPlanningRequestData: data,
      };
    }
    case BOOKING_LISTING_SHIPMENT_PLANNING_REQUEST_FAILED: {
      return {
        ...state,
        bokkingListingShipmentPlanningRequestLoading: false,
        bokkingListingShipmentPlanningRequestData: [],
        bokkingListingShipmentPlanningRequestFailed: true,
      };
    }
    case BOOKING_LISTING_COMPLETED_REQUEST_LOADING: {
      return {
        ...state,
        bokkingListingCompletedRequestLoading: true,
      };
    }
    case BOOKING_LISTING_COMPLETED_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        bokkingListingCompletedRequestLoading: false,
        bokkingListingCompletedRequestData: data,
      };
    }
    case BOOKING_LISTING_COMPLETED_REQUEST_FAILED: {
      return {
        ...state,
        bokkingListingCompletedRequestLoading: false,
        bokkingListingCompletedRequestData: [],
        bokkingListingCompletedRequestFailed: true,
      };
    }
    case BOOKING_LISTING_CANCELED_REQUEST_LOADING: {
      return {
        ...state,
        bokkingListingCanceledRequestLoading: true,
      };
    }
    case BOOKING_LISTING_CANCELED_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        bokkingListingCanceledRequestLoading: false,
        bokkingListingCanceledRequestData: data,
      };
    }
    case BOOKING_LISTING_CANCELED_REQUEST_FAILED: {
      return {
        ...state,
        bokkingListingCanceledRequestLoading: false,
        bokkingListingCanceledRequestData: [],
        bokkingListingCanceledRequestFailed: true,
      };
    }
    case BOOKING_LISTING_DISPUTE_REQUEST_LOADING: {
      return {
        ...state,
        bokkingListingDisputeRequestLoading: true,
      };
    }
    case BOOKING_LISTING_DISPUTE_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        bokkingListingDisputeRequestLoading: false,
        bokkingListingDisputeRequestData: data,
      };
    }
    case BOOKING_LISTING_DISPUTE_REQUEST_FAILED: {
      return {
        ...state,
        bokkingListingDisputeRequestLoading: false,
        bokkingListingDisputeRequestData: [],
        bokkingListingDisputeRequestFailed: true,
      };
    }
    case BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_LOADING: {
      return {
        ...state,
        bokkingUserListingInProgressRequestLoading: true,
      };
    }
    case BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        bokkingUserListingInProgressRequestLoading: false,
        bokkingUserListingInProgressRequestData: data,
      };
    }
    case BOOKING_USER_LISTING_IN_PROGRESS_REQUEST_FAILED: {
      return {
        ...state,
        bokkingUserListingInProgressRequestLoading: false,
        bokkingUserListingInProgressRequestData: [],
        bokkingUserListingInProgressRequestFailed: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default bookingReducer;
