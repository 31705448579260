import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserCompanyData } from "../../../../services/companyService";

const HeaderSection = () => {
  const [getAllCompanyData, setAllCompanyData] = useState([]);
  const dispatcher = useDispatch();
  const companyRData = useSelector((state) => state.companyData);
  const userAccount = useSelector((state) => state.account);
  const appHeader = {
    Authorization: userAccount?.token,
  };
  useEffect(() => {
    let companyId = userAccount?.user?.companyId;
    getUserCompanyData(dispatcher, appHeader, companyId);
  }, [userAccount?.user?.companyId]);

  useEffect(() => {
    console.log(
      "companyRData.userCompanyRequestData",
      companyRData.userCompanyRequestData
    );
    setAllCompanyData(companyRData.userCompanyRequestData);
  }, [companyRData.userCompanyRequestData]);

  return (
    <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
      <Link
        to="/dashboard"
        className="text-center mt-2"
        style={{ width: "100%" }}
      >
        <h2 style={{ color: "white", textTransform: "uppercase" }}>
          {getAllCompanyData[0]?.name}
        </h2>
      </Link>
       
    </div>
  );
};

export default HeaderSection;
