import {
  USER_COMPANY_REQUEST_LOADING,
  USER_COMPANY_REQUEST_DATA,
  USER_COMPANY_REQUEST_FAILED,
} from "../actions/companyActions";

export const initialState = {
  userCompanyRequestLoading: false,
  userCompanyRequestData: [],
  userCompanyRequestFailed: false,
};

//-----------------------|| COMPANY REDUCER ||-----------------------//

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_COMPANY_REQUEST_LOADING: {
      return {
        ...state,
        userCompanyRequestLoading: true,
      };
    }
    case USER_COMPANY_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        userCompanyRequestLoading: false,
        userCompanyRequestData: data,
      };
    }
    case USER_COMPANY_REQUEST_FAILED: {
      return {
        ...state,
        userCompanyRequestLoading: false,
        userCompanyRequestData: [],
        userCompanyRequestFailed: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default companyReducer;
