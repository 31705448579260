import {
  DASHBOARD_ALL_STATUS_COUNT_DATA,
  DASHBOARD_ALL_STATUS_COUNT_FAILED,
  DASHBOARD_ALL_STATUS_COUNT_LOADING,
  DASHBOARD_ALL_TAG_COUNT_DATA,
  DASHBOARD_ALL_TAG_COUNT_FAILED,
  DASHBOARD_ALL_TAG_COUNT_LOADING,
  DASHBOARD_ANNUAL_COUNT_DATA,
  DASHBOARD_ANNUAL_COUNT_FAILED,
  DASHBOARD_ANNUAL_COUNT_LOADING,
  DASHBOARD_MONTH_DATA_COUNT_DATA,
  DASHBOARD_MONTH_DATA_COUNT_FAILED,
  DASHBOARD_MONTH_DATA_COUNT_LOADING,
  DASHBOARD_TOP_LOCATION_DATA_COUNT_DATA,
  DASHBOARD_TOP_LOCATION_DATA_COUNT_FAILED,
  DASHBOARD_TOP_LOCATION_DATA_COUNT_LOADING,
  DASHBOARD_POL_MONTH_DATA_COUNT_DATA,
  DASHBOARD_POL_MONTH_DATA_COUNT_FAILED,
  DASHBOARD_POL_MONTH_DATA_COUNT_LOADING
} from "../actions/dashboardActions";

export const initialState = {
  dashboardAllStatusRequestLoading: false,
  dashboardAllStatusRequestData: [],
  dashboardAllStatusRequestFailed: false,
  dashboardAllTagRequestLoading: false,
  dashboardAllTagRequestData: [],
  dashboardAllTagRequestFailed: false,
  dashboardAnnualRequestLoading: false,
  dashboardAnnualRequestData: [],
  dashboardAnnualRequestFailed: false,
  dashboardMonthDataRequestLoading: false,
  dashboardMonthDataRequestData: [],
  dashboardMonthDataRequestFailed: false,
  dashboardTopLocationDataRequestLoading: false,
  dashboardTopLocationDataRequestData: [],
  dashboardTopLocationDataRequestFailed: false,
  dashboardPolMonthDataRequestLoading: false,
  dashboardPolMonthDataRequestData: [],
  dashboardPolMonthDataRequestFailed: false,
};

//-----------------------|| DASHBOARD REDUCER ||-----------------------//

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_ALL_STATUS_COUNT_LOADING: {
      return {
        ...state,
        dashboardAllStatusRequestLoading: true,
      };
    }
    case DASHBOARD_ALL_STATUS_COUNT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        dashboardAllStatusRequestLoading: false,
        dashboardAllStatusRequestData: data,
      };
    }
    case DASHBOARD_ALL_STATUS_COUNT_FAILED: {
      return {
        ...state,
        dashboardAllStatusRequestLoading: false,
        dashboardAllStatusRequestData: [],
        dashboardAllStatusRequestFailed: true,
      };
    }
    case DASHBOARD_ALL_TAG_COUNT_LOADING: {
      return {
        ...state,
        dashboardAllTagRequestLoading: true,
      };
    }
    case DASHBOARD_ALL_TAG_COUNT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        dashboardAllTagRequestLoading: false,
        dashboardAllTagRequestData: data,
      };
    }
    case DASHBOARD_ALL_TAG_COUNT_FAILED: {
      return {
        ...state,
        dashboardAllTagRequestLoading: false,
        dashboardAllTagRequestData: [],
        dashboardAllTagRequestFailed: true,
      };
    }
    case DASHBOARD_ANNUAL_COUNT_LOADING: {
      return {
        ...state,
        dashboardAnnualRequestLoading: true,
      };
    }
    case DASHBOARD_ANNUAL_COUNT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        dashboardAnnualRequestLoading: false,
        dashboardAnnualRequestData: data,
      };
    }
    case DASHBOARD_ANNUAL_COUNT_FAILED: {
      return {
        ...state,
        dashboardAnnualRequestLoading: false,
        dashboardAnnualRequestData: [],
        dashboardAnnualRequestFailed: true,
      };
    }
    case DASHBOARD_MONTH_DATA_COUNT_LOADING: {
      return {
        ...state,
        dashboardMonthDataRequestLoading: true,
      };
    }
    case DASHBOARD_MONTH_DATA_COUNT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        dashboardMonthDataRequestLoading: false,
        dashboardMonthDataRequestData: data,
      };
    }
    case DASHBOARD_MONTH_DATA_COUNT_FAILED: {
      return {
        ...state,
        dashboardMonthDataRequestLoading: false,
        dashboardMonthDataRequestData: [],
        dashboardMonthDataRequestFailed: true,
      };
    }
    case DASHBOARD_TOP_LOCATION_DATA_COUNT_LOADING: {
      return {
        ...state,
        dashboardTopLocationDataRequestLoading: true,
      };
    }
    case DASHBOARD_TOP_LOCATION_DATA_COUNT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        dashboardTopLocationDataRequestLoading: false,
        dashboardTopLocationDataRequestData: data,
      };
    }
    case DASHBOARD_TOP_LOCATION_DATA_COUNT_FAILED: {
      return {
        ...state,
        dashboardTopLocationDataRequestLoading: false,
        dashboardTopLocationDataRequestData: [],
        dashboardTopLocationDataRequestFailed: true,
      };
    }
    case DASHBOARD_POL_MONTH_DATA_COUNT_LOADING: {
      return {
        ...state,
        dashboardPolMonthDataRequestLoading: true,
      };
    }
    case DASHBOARD_POL_MONTH_DATA_COUNT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        dashboardPolMonthDataRequestLoading: false,
        dashboardPolMonthDataRequestData: data,
      };
    }
    case DASHBOARD_POL_MONTH_DATA_COUNT_FAILED: {
      return {
        ...state,
        dashboardPolMonthDataRequestLoading: false,
        dashboardPolMonthDataRequestData: [],
        dashboardPolMonthDataRequestFailed: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default dashboardReducer;
