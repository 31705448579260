import {
  USER_ALL_REQUEST_LOADING,
  USER_ALL_REQUEST_DATA,
  USER_ALL_REQUEST_FAILED,
} from "../actions/userActions";
export const initialState = {
  userAllRequestLoading: false,
  userAllRequestData: [],
  userAllRequestFailed: false,
};

//-----------------------|| SUPPLIER REDUCER ||-----------------------//

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ALL_REQUEST_LOADING: {
      return {
        ...state,
        userAllRequestLoading: true,
      };
    }
    case USER_ALL_REQUEST_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        userAllRequestLoading: false,
        userAllRequestData: data,
      };
    }
    case USER_ALL_REQUEST_FAILED: {
      return {
        ...state,
        userAllRequestLoading: false,
        userAllRequestData: [],
        userAllRequestFailed: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default userReducer;
