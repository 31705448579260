const LeftNavBar = () => {
  return (
    <div
      className="app-header-menu app-header-mobile-drawer align-items-stretch"
      data-kt-drawer="true"
      data-kt-drawer-name="app-header-menu"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="225px"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_app_header_menu_toggle"
      data-kt-swapper="true"
      data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
      data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
    >
      <div
        className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
        id="kt_app_header_menu"
        data-kt-menu="true"
      >
        <div
          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
          data-kt-menu-placement="bottom-start"
          className="menu-item here show menu-lg-down-accordion menu-here-bg me-0 me-lg-2"
        >
          <span className="menu-link">
            <span className="menu-title">Dashboards</span>
            <span className="menu-arrow d-lg-none"></span>
          </span>
        </div>
      </div>
    </div>
  );
};
export default LeftNavBar;
