export const DASHBOARD_ALL_STATUS_COUNT_LOADING =
  "DASHBOARD_ALL_STATUS_COUNT_LOADING";
export const DASHBOARD_ALL_STATUS_COUNT_DATA =
  "DASHBOARD_ALL_STATUS_COUNT_DATA";
export const DASHBOARD_ALL_STATUS_COUNT_FAILED =
  "DASHBOARD_ALL_STATUS_COUNT_FAILED";

export const DASHBOARD_ALL_TAG_COUNT_LOADING =
  "DASHBOARD_ALL_TAG_COUNT_LOADING";
export const DASHBOARD_ALL_TAG_COUNT_DATA = "DASHBOARD_ALL_TAG_COUNT_DATA";
export const DASHBOARD_ALL_TAG_COUNT_FAILED = "DASHBOARD_ALL_TAG_COUNT_FAILED";


export const DASHBOARD_ANNUAL_COUNT_LOADING =
  "DASHBOARD_ANNUAL_COUNT_LOADING";
export const DASHBOARD_ANNUAL_COUNT_DATA = "DASHBOARD_ANNUAL_COUNT_DATA";
export const DASHBOARD_ANNUAL_COUNT_FAILED = "DASHBOARD_ANNUAL_COUNT_FAILED";


export const DASHBOARD_MONTH_DATA_COUNT_LOADING =
  "DASHBOARD_MONTH_DATA_COUNT_LOADING";
export const DASHBOARD_MONTH_DATA_COUNT_DATA = "DASHBOARD_MONTH_DATA_COUNT_DATA";
export const DASHBOARD_MONTH_DATA_COUNT_FAILED = "DASHBOARD_MONTH_DATA_COUNT_FAILED";


export const DASHBOARD_TOP_LOCATION_DATA_COUNT_LOADING =
  "DASHBOARD_TOP_LOCATION_DATA_COUNT_LOADING";
export const DASHBOARD_TOP_LOCATION_DATA_COUNT_DATA = "DASHBOARD_TOP_LOCATION_DATA_COUNT_DATA";
export const DASHBOARD_TOP_LOCATION_DATA_COUNT_FAILED = "DASHBOARD_TOP_LOCATION_DATA_COUNT_FAILED";


export const DASHBOARD_POL_MONTH_DATA_COUNT_LOADING =
  "DASHBOARD_POL_MONTH_DATA_COUNT_LOADING";
export const DASHBOARD_POL_MONTH_DATA_COUNT_DATA = "DASHBOARD_POL_MONTH_DATA_COUNT_DATA";
export const DASHBOARD_POL_MONTH_DATA_COUNT_FAILED = "DASHBOARD_POL_MONTH_DATA_COUNT_FAILED";
