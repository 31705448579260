import {
    OCEAN_PORT_COUNTRIES_DATA_FAILED,
    OCEAN_PORT_COUNTRIES_DATA_VALUES,
    OCEAN_PORT_COUNTRIES_LOADING,
  } from "../actions/oceanportsActions";
  
  export const initialState = {
    oceanCountriesLoading: false,
    oceanCountriesData: [],
    oceanCountriesFailed: false,
  };
  
  //-----------------------|| ocean ports REDUCER ||-----------------------//
  
  const oceanportsReducer = (state = initialState, action) => {
    switch (action.type) {
      case OCEAN_PORT_COUNTRIES_LOADING: {
        return {
          ...state,
          oceanCountriesLoading: true,
        };
      }
      case OCEAN_PORT_COUNTRIES_DATA_VALUES: {
        const { data } = action.payload;
        return {
          ...state,
          oceanCountriesLoading: false,
          oceanCountriesData: data,
        };
      }
      case OCEAN_PORT_COUNTRIES_DATA_FAILED: {
        return {
          ...state,
          oceanCountriesLoading: false,
          oceanCountriesData: [],
          oceanCountriesFailed: true,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default oceanportsReducer;

  